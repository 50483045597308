<template>
  <main class="text-center p-3 w-100">
    <form @submit.prevent="requestEditTask">
      <div class="codeGenerator d-flex flex-wrap justify-content-center mb-3">
        <select class="form-select" name="location" v-model="task">
          <option value="default" selected>Select a task</option>
          <option v-for="task in getTasks" :key="task.id" :value="task">
            {{ task.name }}
          </option>
        </select>
      </div>
      <div class="form-floating mb-4 w-75 m-auto">
        <input
          id="newName"
          type="text"
          class="form-control"
          v-model="this.task.name"
          placeholder="New Task Name"
          required
        />
        <label for="password">New Task Name</label>
      </div>

      <div class="form-floating mb-4 w-75 m-auto">
        <input
          id="newDescription"
          type="text"
          class="form-control"
          v-model="this.task.description"
          placeholder="New Task Description"
          required
        />
        <label for="password">New Task Description</label>
      </div>
      <div v-if="this.showSuccessMessage" class="alert alert-info">
        Task info was updated successfully.
      </div>
      <div v-else-if="this.showErrorMessage" class="alert alert-danger">
        Something went wrong, please try again.
      </div>
      <button class="w-25 btn btn-lg btn-primary" type="submit">
        Update Info
      </button>
    </form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},

  data() {
    return {
      task: "default",
      showSuccessMessage: false,
      showErrorMessage: false,
    };
  },

  methods: {
    ...mapActions("task", ["editTask"]),
    requestEditTask() {
      if (this.task !== "default") {
        this.editTask({
          taskId: this.task.id,
          info: {
            name: this.task.name,
            description: this.task.description,
          },
        }).then((response) => {
          if (response["success"]) {
            this.showSuccessMessage = true;
            this.showErrorMessage = false;
            return;
          }
        });
      }
      this.showErrorMessage = true;
      this.showSuccessMessage = false;
    },
  },

  computed: {
    ...mapGetters("task", ["getTasks"]),

    verifyRepeatPassword() {
      return this.repeatPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.codeGenerator {
  gap: 2em;
}

.form-select {
  width: auto;
  min-width: 500px;
}
</style>

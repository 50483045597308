<template>
  <main class="text-center p-3 w-100">
    <form @submit.prevent="submitCreateGroup">
      <div class="form-floating mb-4 w-75 m-auto">
        <input
          id="groupName"
          type="text"
          class="form-control"
          v-model="groupName"
          placeholder="Group Name"
          required
        />
        <label for="password">Group Name</label>
      </div>
      <div v-if="this.showSuccessMessage" class="alert alert-info">
        New group created successfully
      </div>
      <button class="w-auto btn btn-lg btn-primary" type="submit">
        Create Group
      </button>
    </form>
  </main>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},

  data() {
    return {
      groupName: "",
      showSuccessMessage: false,
      showErrorMessage: false,
    };
  },

  methods: {
    ...mapActions("task", ["createGroup"]),
    submitCreateGroup() {
      if (this.groupName) {
        this.createGroup(this.groupName).then((response) => {
          if (response["success"]) {
            this.showSuccessMessage = true;
            this.showErrorMessage = false;
            return;
          }

          this.showErrorMessage = true;
          this.showSuccessMessage = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  box-sizing: content-box;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

// .form-signin input[type="email"] {
//   margin-bottom: -1px;
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }

// .form-signin input[type="password"] {
//   margin-bottom: 10px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }
</style>

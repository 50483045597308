<template>
  <main class="text-center p-3 w-100">
    <form @submit.prevent="submitCreateTask">
      <div class="form-floating mb-4 w-75 m-auto">
        <input
          id="taskName"
          type="text"
          class="form-control"
          v-model="taskName"
          placeholder="Task Name"
          required
        />
        <label for="password">Task Name</label>
      </div>
      <div class="form-floating mb-4 w-75 m-auto">
        <input
          id="taskDescription"
          type="text"
          class="form-control"
          v-model="taskDescription"
          placeholder="Task Description"
          required
        />
        <label for="password">Task Description</label>
      </div>
      <div v-if="this.showSuccessMessage" class="alert alert-info">
        New task created successfully
      </div>
      <div v-if="this.showErrorMessage" class="alert alert-danger">
        A task with this name already exists
      </div>
      <button class="w-auto btn btn-lg btn-primary" type="submit">
        Create Task
      </button>
    </form>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},

  data() {
    return {
      taskName: "",
      showSuccessMessage: false,
      showErrorMessage: false,
    };
  },

  computed: {
    ...mapGetters("task", ["getTasks"]),
  },

  methods: {
    ...mapActions("task", ["createTask"]),
    submitCreateTask() {
      if (this.taskName) {
        this.createTask(this.taskName, this.taskDescription).then(
          (response) => {
            if (response["success"]) {
              this.showSuccessMessage = true;
              this.showErrorMessage = false;
              return;
            }

            this.showErrorMessage = true;
            this.showSuccessMessage = false;
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  box-sizing: content-box;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

// .form-signin input[type="email"] {
//   margin-bottom: -1px;
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }

// .form-signin input[type="password"] {
//   margin-bottom: 10px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }
</style>

<template>
  <main class="text-center p-3 w-100">
    <form @submit.prevent="submitCreateTaskGroup">
      <div class="codeGenerator d-flex flex-wrap justify-content-center mb-3">
        <select class="form-select" name="location" v-model="task">
          <option value="default" selected>Select a task</option>
          <option v-for="task in getTasks" :key="task.id" :value="task.name">
            {{ task.name }}
          </option>
        </select>
        <select class="form-select" name="location" v-model="group">
          <option value="default" selected>Select a group</option>
          <option
            v-for="group in getGroups"
            :key="group.id"
            :value="group.name"
          >
            {{ group.name }}
          </option>
        </select>
      </div>
      <div v-if="this.showSuccessMessage" class="alert alert-info">
        New task group created successfully
      </div>
      <button class="w-auto btn btn-lg btn-primary" type="submit">
        Create Task Group
      </button>
    </form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      task: "",
      group: "",
      showSuccessMessage: false,
      showErrorMessage: false,
    };
  },

  methods: {
    ...mapActions("task", ["createTaskGroup"]),
    submitCreateTaskGroup() {
      if (this.groupName) {
        this.createTaskGroup(this.task, this.group).then((response) => {
          if (response["success"]) {
            this.showSuccessMessage = true;
            this.showErrorMessage = false;
            return;
          }

          this.showErrorMessage = true;
          this.showSuccessMessage = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters("task", ["getTasks", "getGroups"]),
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  box-sizing: content-box;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

// .form-signin input[type="email"] {
//   margin-bottom: -1px;
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }

// .form-signin input[type="password"] {
//   margin-bottom: 10px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }
</style>

<template>
  <table class="table">
    <thead></thead>
    <tbody>
      <tr>
        <td>ID</td>
        <td>Name</td>
      </tr>
      <tr v-for="gym in this.groups" :key="gym.id">
        <td>{{ gym.id }}</td>
        <td>{{ gym.name }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      default: () => {
        [];
      },
    },
  },

  data() {
    return {};
  },
};
</script>

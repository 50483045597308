<template>
  <table class="table">
    <thead></thead>
    <tbody>
      <tr>
        <td>ID</td>
        <td>Name</td>
        <td>Description</td>
        <td>Params</td>
      </tr>
      <tr v-for="gym in this.tasks" :key="gym.id">
        <td>{{ gym.id }}</td>
        <td>{{ gym.name }}</td>
        <td>{{ gym.description }}</td>
        <td>{{ gym.params }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tasks: {
      type: Array,
      default: () => {
        [];
      },
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.delegateList {
  max-height: 350px;
}
</style>

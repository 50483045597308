<template>
  <div>
    <h2>Tasks</h2>

    <!-- MENU SELECTOR -->
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <button
          class="nav-link"
          :class="{ active: showOverviewMenu }"
          aria-current="page"
          @click="showMenu('overview')"
        >
          Overview
        </button>
      </li>
      <li v-if="canEditTasks" class="nav-item">
        <button
          class="nav-link"
          :class="{ active: showEditMenu }"
          @click="showMenu('edit')"
        >
          Edit
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          :class="{ active: showGroupsMenu }"
          @click="showMenu('groups')"
        >
          Groups
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          :class="{ active: showTaskGroupMenu }"
          @click="showMenu('taskgroups')"
        >
          Task Groups
        </button>
      </li>
    </ul>

    <!-- OVERVIEW MENU -->
    <div v-if="showOverviewMenu">
      <h3>List of Tasks</h3>
      <tasks-list :tasks="tasks"></tasks-list>
    </div>

    <!-- EDIT MENU -->
    <div v-if="showEditMenu">
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showCreateTask }"
            @click="editMenu('tasksCreate')"
          >
            Create Task
          </button>
        </li>
        <li v-if="canEditTasks" class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showEditTask }"
            @click="editMenu('tasksEdit')"
          >
            Edit Task
          </button>
        </li>
      </ul>
      <div v-if="showCreateTask">
        <h3>Create Task</h3>
        <tasks-create></tasks-create>
      </div>
      <div v-if="showEditTask">
        <h3>Edit Task</h3>
        <tasks-edit></tasks-edit>
      </div>
    </div>

    <!-- REGISTRATION CODE MENU -->
    <div v-if="showGroupsMenu">
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showGroupList }"
            @click="groupsMenu('codeList')"
          >
            Groups List
          </button>
        </li>
        <li v-if="canEditTasks" class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showGroupCreate }"
            @click="groupsMenu('codeCreate')"
          >
            Create Group
          </button>
        </li>
        <li v-if="canCreateRC" class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showGroupEdit }"
            @click="groupsMenu('codeEdit')"
          >
            Edit Group
          </button>
        </li>
      </ul>
      <div v-if="showGroupList">
        <h3>List of Groups</h3>
        <groups-list :groups="groups"></groups-list>
      </div>
      <div v-if="showGroupCreate">
        <h3>Create Groups</h3>
        <group-create></group-create>
      </div>
      <div v-if="showGroupEdit">
        <h3>Edit Groups</h3>
      </div>
    </div>

    <!-- REGISTRATION CODE MENU -->
    <div v-if="showTaskGroupMenu">
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showGroupList }"
            @click="taskgroupsMenu('codeList')"
          >
            Task Groups List
          </button>
        </li>
        <li v-if="canEditTasks" class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showGroupCreate }"
            @click="taskgroupsMenu('codeCreate')"
          >
            Create Task Group
          </button>
        </li>
        <li v-if="canCreateRC" class="nav-item">
          <button
            class="nav-link"
            :class="{ active: showGroupEdit }"
            @click="taskgroupsMenu('codeEdit')"
          >
            Edit Task Group
          </button>
        </li>
      </ul>
      <div v-if="showTaskGroupList">
        <h3>List of Task Groups</h3>
        <groups-list :groups="groups"></groups-list>
      </div>
      <div v-if="showTaskGroupCreate">
        <h3>Create Groups</h3>
        <taskgroup-create></taskgroup-create>
      </div>
      <div v-if="showTaskGroupEdit">
        <h3>Edit Groups</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import groupCreate from "@/components/tasks/groupCreate.vue";
import groupsList from "@/components/tasks/groupsList.vue";
import taskgroupCreate from "@/components/tasks/taskgroupCreate.vue";
import tasksList from "@/components/tasks/tasksList.vue";
import tasksCreate from "@/components/tasks/tasksCreate.vue";
import tasksEdit from "@/components/tasks/tasksEdit.vue";

export default {
  components: {
    tasksCreate,
    tasksList,
    groupCreate,
    tasksEdit,
    groupsList,
    taskgroupCreate,
  },

  data() {
    return {
      showOverviewMenu: false,
      showEditMenu: false,
      showGroupsMenu: false,
      showTaskGroupMenu: false,

      //Edit Locations Menu
      showEditTask: false,
      showCreateTask: false,

      //Registration Code Menu
      showGroupList: false,
      showGroupCreate: false,
      showGroupEdit: false,

      showTaskGroupList: false,
      showTaskGroupCreate: false,
      showTaskGroupEdit: false,
    };
  },

  computed: {
    ...mapGetters("user", ["getUserIsLogged", "getUserData"]),
    ...mapGetters("task", ["getTasks", "getGroups"]),
    tasks() {
      return this.getTasks;
    },
    groups() {
      return this.getGroups;
    },
    canCreateRC() {
      const validRoles = [
        "Mastermind_admin",
        "Location_admin",
        "Location_delegate",
      ];
      return validRoles.includes(this.getUserData.role);
    },

    canEditTasks() {
      const validRoles = [
        "Mastermind_admin",
        "Location_admin",
        "Location_delegate",
      ];
      return validRoles.includes(this.getUserData.role);
    },
  },

  methods: {
    ...mapActions("task", ["tasksData", "groupsData"]),

    showMenu(menu) {
      const menus = {
        overview: () => {
          this.showOverviewMenu = true;
          this.showEditMenu = false;
          this.showGroupsMenu = false;
          this.showTaskGroupMenu = false;
        },
        edit: () => {
          this.showOverviewMenu = false;
          this.showEditMenu = true;
          this.showGroupsMenu = false;
          this.showTaskGroupMenu = false;
        },
        groups: () => {
          this.showOverviewMenu = false;
          this.showEditMenu = false;
          this.showGroupsMenu = true;
          this.showTaskGroupMenu = false;
        },
        taskgroups: () => {
          this.showOverviewMenu = false;
          this.showEditMenu = false;
          this.showGroupsMenu = false;
          this.showTaskGroupMenu = true;
        },
      };
      menus[menu]();
    },

    taskgroupsMenu(menu) {
      const menus = {
        codeList: () => {
          this.showTaskGroupList = true;
          this.showTaskGroupCreate = false;
          this.showTaskGroupEdit = false;
        },
        codeCreate: () => {
          this.showTaskGroupList = false;
          this.showTaskGroupCreate = true;
          this.showTaskGroupEdit = false;
        },
        codeEdit: () => {
          this.showTaskGroupList = false;
          this.showTaskGroupCreate = false;
          this.showTaskGroupEdit = true;
        },
      };

      menus[menu]();
    },

    groupsMenu(menu) {
      const menus = {
        codeList: () => {
          this.showGroupList = true;
          this.showGroupCreate = false;
          this.showGroupEdit = false;
        },
        codeCreate: () => {
          this.showGroupList = false;
          this.showGroupCreate = true;
          this.showGroupEdit = false;
        },
        codeEdit: () => {
          this.showGroupList = false;
          this.showGroupCreate = false;
          this.showGroupEdit = true;
        },
      };

      menus[menu]();
    },

    editMenu(menu) {
      const menus = {
        tasksCreate: () => {
          this.showCreateTask = true;
          this.showEditTask = false;
        },
        tasksEdit: () => {
          this.showCreateTask = false;
          this.showEditTask = true;
        },
      };

      menus[menu]();
    },
  },

  mounted() {
    this.tasksData();
    this.groupsData();
    this.showMenu("overview");
  },
};
</script>

<style lang="scss" scoped>
.delegateList {
  max-height: 350px;
}
</style>
